import React, { useContext } from 'react'

import Layout from '../components/Layout'
import { useSiteMetadata } from '../hooks'
import Hero from '../components/Hero'
import { graphql, Link, useStaticQuery } from 'gatsby'
import FilterContext from '../context/FilterContext'

export default function Speakers() {
  const { tags, countries, locations, genders, currentTag, setCurrentTag } = useContext(
    FilterContext,
  )
  const { title, description } = useSiteMetadata()
  const { allPerson } = useStaticQuery(graphql`
    query People {
      allPerson {
        nodes {
          location
          gender
          country
          description
          id
          name
          tags
          excerpt
          twitter
          url
          company
          additionalSpeakers {
            name
            twitter
            url
            image
            company
          }
          image
        }
      }
    }
  `)
  const people = allPerson.nodes.filter(
    person =>
      currentTag === 'all' ||
      person.tags.includes(currentTag) ||
      currentTag === person.country ||
      currentTag === person.gender ||
      currentTag === person.location,
  )

  return (
    <Layout
      title={`Speakers | ${title}`}
      description={description}
    >
      <Hero className={'hero is-transparent is-medium'} isIndex={false} title={'Speakers'}/>

      <div className={'columns has-padding-top-30 has-padding-bottom-30 has-padding-20-mobile'}>
        <div className={'column is-offset-one-quarter is-half is-offset-1-tablet is-10-tablet'}>
          <p>ℹ️ Click on one of the tags below to filter our speakers</p>
          <div className={'tags has-addons are-medium has-text-weight-semibold has-padding-bottom-20'}>
            {tags.map(tag => (
              <div className={'has-padding-5'}>
                <label
                  className={'tag'}
                  currentTag={tag.name === currentTag}
                  htmlFor={`filter-${tag.name}`}
                  key={`filter-${tag.name}`}
                  clickable
                >
                  <input
                    type="radio"
                    name="tag"
                    id={`filter-${tag.name}`}
                    value={tag.name}
                    checked={tag.name === currentTag}
                    onChange={e => setCurrentTag(e.currentTarget.value)}
                  />
                  {tag.name}
                </label>
                <span className={'tag is-info'}>{tag.count}</span>
              </div>
            ))}

            {locations.map(location => (
              <div className={'has-padding-5'}>
                <label
                  currentTag={location.name === currentTag}
                  htmlFor={`filter-${location.name}`}
                  key={`filter-${location.name}`}
                  title={location.name}
                  clickable
                >
                  <input
                    type="radio"
                    name="tag"
                    id={`filter-${location.name}`}
                    value={location.name}
                    checked={location.name === currentTag}
                    onChange={e => setCurrentTag(e.currentTarget.value)}
                  />
                  <span className={'tag'}>{location.name}</span>
                  <span className={'tag is-info'}>{location.count}</span>
                </label>
              </div>
            ))}

            {genders.map(gender => (
              <div className={'has-padding-5'}>
                <label
                  currentTag={gender.name === currentTag}
                  htmlFor={`filter-${gender.name}`}
                  key={`filter-${gender.name}`}
                  title={gender.name}
                  clickable
                >
                  <input
                    type="radio"
                    name="tag"
                    id={`filter-${gender.name}`}
                    value={gender.name}
                    checked={gender.name === currentTag}
                    onChange={e => setCurrentTag(e.currentTarget.value)}
                  />
                  <span className={'tag'}>{gender.name}</span>
                  <span className={'tag is-info'}>{gender.count}</span>
                </label>
              </div>
            ))}

            {countries.map(tag => (
              <div className={'has-padding-5'}>
                <label
                  currentTag={tag.emoji === currentTag}
                  htmlFor={`filter-${tag.name}`}
                  key={`filter-${tag.name}`}
                  title={tag.name}
                  clickable
                >
                  <input
                    type="radio"
                    name="tag"
                    id={`filter-${tag.name}`}
                    value={tag.emoji}
                    checked={tag.emoji === currentTag}
                    onChange={e => setCurrentTag(e.currentTarget.value)}
                  />
                  <span className={'tag'}>{tag.emoji}</span>
                  <span className={'tag is-info'}>{tag.count}</span>
                </label>
              </div>
            ))}
          </div>

          <div className={'columns has-text-centered'}>
            <div className={'column'}>
              <div className={'columns has-padding-bottom-50 is-multiline is-centered'}>
                {people.map(person => {
                  const url = new URL(person.url)
                  const website = `https://unavatar.now.sh/${url.host}`
                  const twitter = `https://res.cloudinary.com/df1zyk23d/image/twitter_name/${person.twitter}.png`
                  let img = person.twitter ? `https://images.weserv.nl/?url=${twitter}&w=100&l=9&af&il&n=-1`
                    : `https://images.weserv.nl/?url=${website}&w=100&l=9&af&il&n=-1`
                  img = person.image === '' ? img : person.image

                  return (
                    <div key={person.description} className="column is-12">
                      <div className="card">
                        <div className="card-content">
                          <p className="title is-5 has-text-left">
                            {person.description}
                          </p>
                          <article className="has-text-left" dangerouslySetInnerHTML={{ __html: person.excerpt }}/>
                          <div className="content">
                            <div className={'tags has-padding-top-15'}>
                              {person.tags.map(tag => (
                                <span className={'tag is-info'}>{tag}</span>
                              ))}
                            </div>
                          </div>
                          <div className="media is-borderless">
                            <div className="media-content has-text-right">
                              <p className="title is-6">{person.name} <span className={'subtitle is-7'}>{person.company ? `(${person.company})` : ''}</span></p>
                              <p className="subtitle is-6">
                                <a
                                  href={`https://www.twitter.com/${person.twitter}`}
                                  title={person.name}
                                  rel={person.name}
                                >
                                  {person.twitter}</a>
                              </p>
                            </div>
                            <div className={'media-right'}>
                              <figure className="image is-48x48">
                                <img className={'is-rounded'} src={img}
                                     alt={person.name}/>
                              </figure>
                            </div>
                          </div>
                          {person.additionalSpeakers.map(p => {
                            const _url = new URL(p.url)
                            const _website = `https://unavatar.now.sh/${_url.host}`
                            const _twitter = `https://res.cloudinary.com/df1zyk23d/image/twitter_name/${p.twitter}.png`
                            let _img = p.twitter ? `https://images.weserv.nl/?url=${_twitter}&w=100&l=9&af&il&n=-1`
                              : `https://images.weserv.nl/?url=${_website}&w=100&l=9&af&il&n=-1`
                            _img = p.image === '' ? _img : p.image

                            return (<div className="media is-borderless">
                              <div className="media-content has-text-right">
                                <p className="title is-6">{p.name} <span className={'subtitle is-7'}>({p.company})</span></p>
                                <p className="subtitle is-6">
                                  <a
                                    href={`https://www.twitter.com/${p.twitter}`}
                                    title={p.name}
                                    rel={p.name}
                                  >
                                    {p.twitter}</a>
                                </p>
                              </div>
                              <div className={'media-right'}>
                                <figure className="image is-48x48">
                                  <img className={'is-rounded'} src={_img}
                                       alt={p.name}/>
                                </figure>
                              </div>
                            </div>)
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export { Speakers }
